$(document).ready(function () {

    $('body').jpreLoader({
        splashID: "#jSplash",
        loaderVPos: '50%',
        autoClose: true
    });

    // 電腦版menu
    // 最新消息
    $('.pc-nav li:nth-child(1)').click(function (e) {
        $('html,body').animate({
            scrollTop: $('.p2-content').offset().top
        }, 800);
        e.preventDefault();
    });

    // 合作品牌
    $('.pc-nav li:nth-child(2)').click(function (e) {
        $('html,body').animate({
            scrollTop: $('.p3-content').offset().top
        }, 800);
        e.preventDefault();
    });

    // 合作案例
    $('.pc-nav li:nth-child(3)').click(function (e) {
        $('html,body').animate({
            scrollTop: $('.p4-content').offset().top
        }, 800);
        e.preventDefault();
    });

    // 服務項目
    $('.pc-nav li:nth-child(4)').click(function (e) {
        $('html,body').animate({
            scrollTop: $('.p5-content').offset().top
        }, 800);
        e.preventDefault();
    });

    // 重要經歷
    $('.pc-nav li:nth-child(5)').click(function (e) {
        $('html,body').animate({
            scrollTop: $('.p6-content').offset().top
        }, 800);
        e.preventDefault();
    });

    // 公司簡介
    $('.pc-nav li:nth-child(6)').click(function (e) {
        $('html,body').animate({
            scrollTop: $('.p7-wrap').offset().top
        }, 800);
        e.preventDefault();
    });


    // 漢堡
    $('.ham').click(function () {
        $(this).toggleClass('active');
        $('#overlay').toggleClass('open');
        $('.body').toggleClass('noscroll');
    });
    $('.overlay-content').click(function () {
        $('#overlay').toggleClass('open');
        $('.body').toggleClass('noscroll');
    });

    // 最新消息
    $('.overlay li:nth-child(1)').click(function (e) {
        $('html,body').animate({
            scrollTop: $('.p2-content').offset().top
        }, 800);
        e.preventDefault();
    });

    // 合作品牌
    $('.overlay li:nth-child(2)').click(function (e) {
        $('html,body').animate({
            scrollTop: $('.p3-content').offset().top
        }, 800);
        e.preventDefault();
    });

    // 合作案例
    $('.overlay li:nth-child(3)').click(function (e) {
        $('html,body').animate({
            scrollTop: $('.p4-content').offset().top
        }, 800);
        e.preventDefault();
    });

    // 服務項目
    $('.overlay li:nth-child(4)').click(function (e) {
        $('html,body').animate({
            scrollTop: $('.p5-content').offset().top
        }, 800);
        e.preventDefault();
    });

    // 重要經歷
    $('.overlay li:nth-child(5)').click(function (e) {
        $('html,body').animate({
            scrollTop: $('.p6-content').offset().top
        }, 800);
        e.preventDefault();
    });

    // 公司簡介
    $('.overlay li:nth-child(5)').click(function (e) {
        $('html,body').animate({
            scrollTop: $('.p7-content').offset().top
        }, 800);
        e.preventDefault();
    });

    // p1 - slider
    $('.p1-slider-pc').slick({
        arrows: true,
        dots: false
    });
    $('.p1-slider-m').slick({
        arrows: false,
        dots: true
    });

    $('.p1-prev').on('click', function () {
        $('.p1-content .slick-prev').trigger('click');
    });

    $('.p1-next').on('click', function () {
        $('.p1-content .slick-next').trigger('click');
    });

    // p2 - slider
    $('.p2-slider').slick({
        arrows: true,
        dots: false,
        slidesToShow: 3,
        responsive: [{
            breakpoint: 992,
            settings: {
                arrows: false,
                dots: true,
                slidesToShow: 1
            }
        }]
    });

    $('.p2-prev').on('click', function () {
        $('.p2-content .slick-prev').trigger('click');
    });

    $('.p2-next').on('click', function () {
        $('.p2-content .slick-next').trigger('click');
    });

    $('.p6-content .more').on('click', function () {
        $('.p6-content .more-experience').show();
        $(this).hide();
    })


    // gotop
    $('.gotop').click(function (e) {
        $('html,body').animate({
            scrollTop: $('.goldline').offset().top
        }, 800);
        e.preventDefault();
    });

    $('.gotop').hover(function (e) {
        $(this).toggleClass('top-hover');
    });


    $('.p1-content.animated').addClass('fadeInDown');


    // animation

    var LockNews = true;
    var LockBrand = true;
    var LockProject = true;

    $(window).scroll(function () {

        var windowRatio = ($(window).height() / 100) * 70;
        var Newsscrolltop = $('.p2-content').offset().top - $(window).scrollTop();
        var Brandscrolltop = $('.p3-content').offset().top - $(window).scrollTop();
        var Projectscrolltop = $('.p4-content').offset().top - $(window).scrollTop();

        if ((Newsscrolltop < windowRatio) && (LockNews == true)) {
            $(".p2-slider-item").velocity("transition.slideDownIn", {
                stagger: 300
            });
            LockNews = false;
        }

        if ((Brandscrolltop < windowRatio) && (LockBrand == true)) {
            $(".p3-wrap .col-12.col-lg-4").velocity("transition.slideDownIn", {
                stagger: 300
            });
            LockBrand = false;
        }

        if ((Projectscrolltop < windowRatio) && (LockProject == true)) {
            $(".p4-wrap .col-12.col-lg-4").velocity("transition.slideDownIn", {});

            LockProject = false;
        }
    });

    // 複製連結Copy Link
    function ClipCopy(e) {
        var copyText = e;
        e.select();
        document.execCommand("Copy");
    }
});